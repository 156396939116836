
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import { setCookie } from "typescript-cookie";

export default defineComponent({
  name: "password-reset",
  components: {
    ErrorMessage,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const phone = ref();
    const password = ref("");
    const token = ref();
    const hide = ref(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const okeybutton = async (values) => {
      let newphone: string = phone.value;
      let postphone = newphone[0] == "0" ? newphone.slice(1) : newphone;
      const data = {
        phoneNumber: postphone,
      };
      store.dispatch(Actions.FORGOTREGISTER, data).then((res) => {
        token.value = res.data.token;
        hide.value = true;
      });
    };
    //Form submit function
    const onSubmitForgotPassword = async () => {
      let newphone: string = phone.value;
      let postphone = newphone[0] == "0" ? newphone.slice(1) : newphone;
      const values = {
        token: token.value,
        phoneNumber: postphone,
        password: password.value,
      };
      store.dispatch(Actions.FORGOTVERIFY, values).then((res: any) => {
        if (res.status == 201) {
          setCookie("access_token", res.data.access_token);
          setCookie("refresh_token", res.data.refresh_token);
          router.push({ name: "dashboard" });
        } else {
          Swal.fire({
            text: "Kullanıcı bilgileri doğrulanamadı",
            buttonsStyling: false,
            confirmButtonText: "Tekrar dene",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    };

    return {
      onSubmitForgotPassword,
      hide,
      token,
      okeybutton,
      phone,
      password,
      submitButton,
    };
  },
});
